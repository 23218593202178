import React, { useState } from 'react'
import ManagementNav from './ManagementNav';


function CertifiedInformationSystem() {
  const [tabIndex, setTabIndex] = useState(1);

  return (
    <div>
      <div className="page-header">
        <div className="page-header__bg"
          style={{ backgroundImage: `url('/assets/images/background/page-header-bg-1-1.jpg')` }}></div>
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li><a href="{{ url('/') }}">Home</a></li>
            <li><a href="{{ url('/services/security-training') }}">Training</a></li>
            <li>Management</li>
          </ul>
          <h2 className="page-header__title">Certified Information System Security Professional (CISSP)</h2>
        </div>
      </div>
      <section className=" section-padding--top">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">


              <h3 style={{ color: "orange" }} className="blog-card-one__title blog-details__title">Certified Information System Security Professional (CISSP)</h3>
              <div className="blog-details__content">
                <p>
                  The Certified Information System Security Professional (CISSP) course is one of the most comprehensive courses available for the preparation of the CISSP certification exam. The certification is offered by (ISC)2 and is among the most highly sought-after certifications in the IT industry. The course reviews in great detail the information security concepts and industry best practices and covers the eight domains of the official CISSP CBK (Common Body of Knowledge). The candidates are able to gain knowledge in information security that increases their ability to successfully implement and manage security programs in any organization.</p>
              </div>


            </div>
            <div className="col-lg-5 mt-5">
              <ManagementNav />
            </div>
          </div>
        </div>
      </section>
      <div className="container ">
        <div>
          <div>
            <ul className="nav nav-sm nav-tabs nav-vertical mb-4 steps-sampling">
              <li className="nav-item">
                <a className="nav-link active" onClick={() => setTabIndex(1)} id="link1" href="javascript:void(0)" >
                  <i className="bi-list-check nav-icon"></i>Course Highlight
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => setTabIndex(2)} href="javascript:void(0)" >
                  <i className="bi-file-arrow-up nav-icon"></i> How to Prepare
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => setTabIndex(3)} href="javascript:void(0)" >
                  <i className="bi-sort-numeric-down nav-icon"></i> Schedule
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => setTabIndex(4)} href="javascript:void(0)" >
                  <i className="bi-sort-numeric-down nav-icon"></i> Price
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => setTabIndex(5)} href="javascript:void(0)" >
                  <i className="bi-sort-numeric-down nav-icon"></i> Information
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => setTabIndex(6)} href="javascript:void(0)" >
                  <i className="bi-sort-numeric-down nav-icon"></i> Registration
                </a>
              </li>

            </ul>

          </div>
        </div>
      </div>


      <section className="mt-1">
        <div className="container">

          {
            tabIndex === 1 && (
              <div className="row">
                <div className="col-lg-8">


                  <h3 style={{ color: "orange" }} className="blog-card-one__title blog-details__title">Course Highlight</h3>
                  <div className="blog-details__content">
                    <p>
                      The goal of this preparatory course is to help the students achieve the (ISC)2 CISSP certification. The course will enable the students to validate their knowledge about information security in general and the eight domains of the CISSP exam in particular. The course alumni are supposed to become involved in critical security decisions and risk management.
                      On successful completion of this course, the students shall be able to completely learn and gain an in-depth understanding of the eight domains of CISSP.<br />
                      The major domains covered in this course are:<br />
                      ●	Security and Risk Management<br />
                      ●	 Asset Security<br />
                      ●	Security Architecture and Engineering<br />
                      ●	 Communication and Network Security<br />
                      ●	Identity and Access Management (IAM)<br />
                      ●	 Security Assessment and Testing<br />
                      ●	Security Operations<br />
                      ●	 Software Development Security<br />
                    </p>
                  </div>


                </div>
                <div className="col-lg-4 mt-5">
                  <div className="right border rounded-3 shadow " style={{ width: "100%" }}>
                    <div className="card-body p-3">
                      <h5 className='mt-4'><a href="/training" >Ready For You ?</a></h5>


                      <p className='mt-4'>Upon completion of the course, you’ll be able to conduct PCI 3DS Assessments, validate and attest as to an entity’s PCI 3DS Core Security Standard compliance status, and prepare appropriate compliance reports<br /> (such as Reports on Compliance (RoC)) required by payment card brands and acquiring banks. </p>
                      <button type="button" className="btn btn-warning mt-4 w-100">Register Now</button>
                    </div>
                  </div>
                </div>
              </div>

              // <h3>Frequently Asked Questions</h3>
              // <p>Regular assessments as such help safeguard the application from any unauthorized access which can cause an impact on the organization both in reputation and resources</p>

            )
          }
          {
            tabIndex === 2 && (
              <div className="row">
                <div className="col-lg-8">


                  <h3 style={{ color: "orange" }} className="blog-card-one__title blog-details__title">How To Prepare For The Exam</h3>
                  <div className="blog-details__content">
                    <p>
                      ●	Ø  Know what the CISSP exam is and what topics it covers<br />
                      ●	Ø  Create your study plan and make use of sources<br />
                      ●	Ø  Get involved in practice tests and mock exams<br />
                      ●	Ø  Join study groups or online communities<br />
                      ●	Ø  Focus more on weaker domains<br />
                      ●	Ø  Schedule your time and learning<br />
                      ●	Ø  Practice scenario-based or judgement questions<br />
                      ●	Ø  Have an exam day preparation plan<br />
                      ●	Ø  Get a good night's sleep before the exam and clear your mind <br />
                      ●	Ø  Implement your exam strategy<br />

                    </p>
                  </div>


                </div>
                <div className="col-lg-4 mt-5">
                  <div className="right border rounded-3 shadow " style={{ width: "100%" }}>
                    <img className="card-img-top rounded-3 w-100" src="/assets/images/training/work.png" alt="" />
                    <div className="card-body p-3">
                      <h6 mt-4>Upon completion of the course, you’ll be able to conduct PCI 3DS Assessments, validate and attest as to an entity’s PCI 3DS</h6>

                      <h5 className='mt-4'><a href="/training" >Request More Information</a></h5>
                      <button type="button" className="btn btn-warning mt-4 w-100">Register Now</button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            tabIndex === 3 && (
              <div className="row">
                <div className="col-lg-8">


                  <h3 style={{ color: "orange" }} className="blog-card-one__title blog-details__title">Schedule</h3><hr />
                  <div className="blog-details__content">
                    <div className=" container d-flex justify-content-between">
                      <h5 style={{ color: "black" }}>17 NOV 2022</h5>
                      <h6> 9:00 - 17:20 BST</h6>
                      <h6>Remote</h6>


                    </div><hr />
                    <p>

                      By delivering training that aligns with the organization's specific context, RightTime helps to enhance knowledge, skills, and competencies within the organization.
                    </p>
                  </div>


                </div>
                <div className="col-lg-4 mt-5">
                  <div className="right border rounded-3 shadow " style={{ width: "100%" }}>
                    <img className="card-img-top rounded-3 w-100" src="/assets/images/training/book.png" alt="" />
                    <div className="card-body p-3">
                      <p>
                        required by payment card brands and acquiring banks.
                      </p>
                      <button type="button" className="btn btn-warning mt-4 w-100">Register Now</button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            tabIndex === 4 && (
              <div className="row">
                <div className="col-lg-8">


                  <h3 style={{ color: "orange" }} className="blog-card-one__title blog-details__title">Price</h3><hr />
                  <div className="blog-details__content">

                  </div><hr />
                  <div className=" container d-flex justify-content-between">
                    <h5 style={{ color: "black" }}>New 3DS Assessor training (In person or eLearning)</h5>
                    <h6>$1368 USD</h6>
                    <h6>$1368 USD</h6>


                  </div><hr />
                  <div className=" container d-flex justify-content-between">
                    <h5 style={{ color: "black" }}>Requalification 3DS Assessor Training</h5>
                    <h6>$1368 USD</h6>
                    <h6>$1368 USD</h6>


                  </div><hr />
                  <div className=" container d-flex justify-content-between">
                    <h5 style={{ color: "black" }}>Knowledge Training Non-PO *</h5>
                    <h6> $1368 USD</h6>
                    <h6> </h6>


                  </div><hr />
                  <div className=" container d-flex justify-content-between">
                    <h5 style={{ color: "black" }}>Knowledge Training PO *</h5>
                    <h6> $1368 USD</h6>
                    <h6></h6>

                  </div><hr />
                  <p>

                    By delivering training that aligns with the organization's specific context, RightTime helps to enhance knowledge, skills, and competencies within the organization.
                  </p>


                </div>
                <div className="col-lg-4 mt-5">
                  <div className="right border rounded-3 shadow " style={{ width: "100%" }}>
                    <img className="card-img-top rounded-3 w-100" src="/assets/images/training/man.png" alt="" />
                    <div className="card-body p-3">
                      <p>
                        required by payment card brands and acquiring banks.
                      </p>
                      <a href="/managent" className=" mt-4 ">More Information</a>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          {
            tabIndex === 5 && (
              <div>
                Examination Information-CAT<br />
                ·        Computerized Adaptive Testing<br />
                ·        Duration of exam = 3 hrs<br />
                ·        Number of questions=100 - 150<br />
                ·        Item format = Multiple choice and advanced innovative items<br />
                ·        Passing grade = 700 out of 1000 points<br />
                ·        Exam language availability = English<br />
                ·        Testing center = (ISC)2 Authorized PPC and PVTC (Pearson VUE Testing Centers)<br />

                Examination Information-Linear<br />
                ·        Duration of exam = 6 hrs<br />
                ·        Number of questions=250<br />
                ·        Item format = Multiple choice and advanced innovative items<br />
                ·        Passing Grade = 700 out of 1000 points<br />
                ·        Exam language availability = French, German, Brazilian Portuguese, Spanish-Modern, Japanese, Simplified Chinese and Korean.<br />
                ·        Testing center = (ISC)2 Authorized PPC and PVTC (Pearson VUE Testing Centers)<br />


              </div>
            )
          }

          {
            tabIndex === 6 && (
              <div className="row">
                <div className="col-lg-8">


                  <h3 style={{ color: "orange" }} className="blog-card-one__title blog-details__title">Registration Process</h3>
                  <div className="blog-details__content">
                    <p>
                      “Right Time Limited” is a highly esteemed security service provider, assisting businesses in fortifying their security posture. It offers expert consultation, meticulous testing, certification support, and reliable managed services to safeguard assets and mitigate risks. teaches you to perform assessments of 3DS Environments in accordance with the PCI 3DS Core Security Standard. This training course will provide you with the understanding of the logical and physical security requirements as well as assessment procedures for performing PCI 3DS Assessments.
                      <br />
                      By delivering training that aligns with the organization's specific context, RightTime helps to enhance knowledge, skills, and competencies within the organization.
                    </p>
                  </div>


                </div>
                <div className="col-lg-4 mt-5">
                  <button type="button" className="btn btn-warning mt-4 w-100">Register Now</button>


                </div>
              </div>
            )
          }
        </div>

      </section>


      <div className="page-header mt-4" >
        <div className="page-header__bg"
          style={{ backgroundImage: `url('https://www.icorps.com/hs-fs/hubfs/Hero%20Image%2021-1.png?width=1500&height=600&name=Hero%20Image%2021-1.png')` }}></div>
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <h2 className="text-light">Digital Badging</h2>
          </ul>
          <p className="text-light">“Right Time Limited” is a highly esteemed security service provider, assisting businesses in fortifying their security posture. It offers expert consultation, meticulous testing, certification support, and reliable managed services to safeguard assets and mitigate risks.</p>
        </div>
      </div>

      <br /><br /><br /><br />
    </div>
  )
}

export default CertifiedInformationSystem