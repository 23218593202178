import React from 'react'
import Allpage from '../../../components/all-page/Allpage'

function Events() {
  return (
    <Allpage data="News & events"/>

   )
}

export default Events