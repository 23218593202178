export const OfferData = [
    {
        id: 1,
        title: 'Initial study (Business Understanding)',
        description: 'Perform an initial study of business to understand your card processes, the environment and accordingly combine the PCI scope.',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
 

    },
    {
        id: 2,
        title: 'Scope Finalization ',
        description: ' Confirm systems that fall under the PCI DSS scope and articulate the scope statement.',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },

    {
        id: 3,
        title: 'Gap Analysis (Readiness  Assessment) ',
        description: 'Identify gaps including VA & PT (internal & External) in your organization’s security controlsystems and environment vis-à-vis PCI DSS requirements',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },

    {
        id: 4,
        title: 'Data Flow/ Leakage Assessment',
        description: 'Perform a thorough Data Flow/Leakage   assessment of your application and assist in remediation.',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },
    {
        id: 5,
        title: 'Awareness & Implementation Training',
        description: ' Confirm systems that fall under the PCI DSS scope and articulate the scope statement.',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },
    {
        id: 6,
        title: 'Data & Assets Classification',
        description: ' Assist Identifying your information assets across the organization and assist clients/customers classifying them as per criticality',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },
    {
        id: 7,
        title: 'Risk Assessment  ',
        description: ' Perform risk assessment to identify assets exposed to risk and assess how it could impact your organization',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },
    {
        id: 8,
        title: 'Risk Treatment (Remediation Support) ',
        description: 'Assist client for detailed remediation strategies including the recommendation of compensating controls as applicable that can help your organization strengthen the security posture of the environment.',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },
    {
        id: 9,
        title: 'Documentation Support & Policy Role Out',
        description: 'Assist for Technical Documentation Modify/Formulate/Edit policies and procedures as per PCI DSS requirements which are then finalized by relevant team and then approved by the top management. And assist for implementing necessary policies for your organization',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },
    
    {
        id: 10,
        title: 'Pre-Assessment',
        description: 'After a reasonable maturation period, our distinct team of experts conducts a Preassessment (internal audit) of your setup to check whether the suggested measures are implemented and in place',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },


    {
        id: 11,
        title: 'Evidence Review ',
        description: 'Review of the evidence collected to assess their maturity, in line with the compliance',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },
    {
        id: 12,
        title: 'Audit & Attestation',
        description: ' Once all controls are confirmed to be in place, we help you get attested with our own duly segregated QSA audit team or any external auditors of your choice.',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },

    {
        id: 13,
        title: 'Scope Finalization ',
        description: ' Support you in maintaining compliance by providing guidelines.',
         image: 'https://www.technovus.com/wp-content/uploads/2019/10/PCI-DSS-Compliance.jpg',
        header:"PCI DSS"
    },

]