import React from 'react'
 import Accordion from 'react-bootstrap/Accordion';
 
function SeviceCommon() {
  return (
    <div>
         <div className="mx-5 px-5">
           <div>
        <img  src="assets/images/services/ani.png" width="100%" alt=''/>
      </div>
      </div>
     
      

      <section id="benefit" className="section-padding--bottom text-center">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Company Benefits</p>
            <h2 className="header">We Provide Best Information Security <br /> Service and Solution For 14 Years</h2>

          </div>
          <div className="row   text-center col-xs-6">
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/1.png" width="30%" alt=''/>
                    <h5 className=' mt-3 p-2 text-dark'>Business Understanding</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">
                      Evaluating business process and environment to understand the in-scope elements</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/2.png" width="30%" alt=''/>                    
                    <h5 className='mt-3 p-2 text-dark'>Scope Finalization</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">
                      Finalize the scope elements and prepare the requirement documentation</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/3.png" width="30%" alt=''/>
                    <h5 className='mt-3 p-2 text-dark'>Readiness Assesment</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">
                      Identify the potential challenges that might arise during requirement implementation</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-4">
                    <img src="assets/images/services/cardicon/1.png" width="30%" alt=''/>
                    <h5 className='mt-3 p-2 text-dark'>Risk Assesment</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">
                      Identify the potential challenges that might arise during requirement implementation</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">

                    <img src="assets/images/services/cardicon/5.png" width="30%" alt=''/>                   
                     <h5 className='mt-3 p-2 text-dark'>Data Flow Assesment</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">Data Flow Assessment
                      Conducting thorough systems analysis to evaluate data flow and possible leakages</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/6.png" width="30%" alt=''/>                   
                     <h5 className='mt-3 p-2 text-dark'>Doccumentation Support</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">
                      Assist you with list of policy and procedure to help you in validation or evidence collection</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/7.png" width="30%" alt='' />                   
                     <h5 className='mt-3 p-2 text-dark'>Remidiation Support</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">
                      Assist you with list of policy and procedure to help you in validation or evidence collection</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/8.png" width="30%" alt=''/>                    
                    <h5 className='mt-3 p-2 text-dark'>Awareness Training</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">
                      Conduct awareness sessions for your Team and personnel involved in the scope</p>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="row gutter box text-center col-xs-6">
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/9.png" width="30%" alt=''/>                    
                    <h5 className='mt-3 p-2 text-dark'>Scan And Testing</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">Scans And Testing
                      Identify critical vulnerabilities in your system with a robust testing approach</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/10.png" width="30%" alt=''/>                    
                    <h5 className='mt-3 p-2 text-dark'>Evidence And Reveiw</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4">Evidence Review
                      Review of the evidence collected to assess their maturity, in line with the compliance</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/11.png" width="30%" alt=''/>
                    <h5 className='mt-3 p-2 text-dark'>Final Assesment And Attestation</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4"> Attestation
                      Post successful assessment, we get you attested for compliance with our audit team</p>

                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 rounded ">
              <div id="card-container">
                <div className="text-center rounded" id="card3">
                  <div className="front face text-center pt-3">
                    <img src="assets/images/services/cardicon/12.png" width="30%" alt=''/>
                    <h5 className='mt-3 p-2 text-dark'>Contineuos Compliance</h5>
                  </div>
                  <div className="back face">
                    <p className="artist mt-4"> Attestation
                      Post successful assessment, we get you attested for compliance with our audit team</p>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section id="question" className="section-padding--bottom text-center">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="header">Frequently Asked Questions</h2>
            <div className="expanedItem mt-5 mb-5">
              <div className=" expaneditems1">
                <Accordion className="shadaow">
                  <Accordion.Item className="my-3" eventKey="1">
                    <Accordion.Header>What Is The Difference Between CHD And SAD ?</Accordion.Header>
                    <Accordion.Body>
                      Account Data is Organized into two data groups. 1) Card Holder Data (CHD) 2) Sensitive Authentication Data (SAD). CHD covers the Data elements like Primary Account Number (PAN), Cardholder Name, Service Code and Expiration Date. CHD is useful to identify the Card holder, where in SAD Covers data elements like Track Data, CVV, CVC, CAV, CID, PIN / PIN Block. SAD is used for authorizing the card holder to do the transactions.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion >
                  <Accordion.Item className="my-3" eventKey="1">
                    <Accordion.Header>To Whom Does The PCI DSS Apply ?</Accordion.Header>
                    <Accordion.Body>
                      PCI DSS standard can be applied to any organization that accepts, transmits or stores any cardholder data regardless of size or number of transactions.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion >
                  <Accordion.Item className="my-3" eventKey="1">
                    <Accordion.Header>What Is The Most Current Version Of The PCI DSS ?</Accordion.Header>
                    <Accordion.Body>
                      The latest is PCI DSS version 3.2.1 that replaces version 3.2. Though no new requirements were added in PCI DSS 3.2.1, the update was designed to eliminate any confusion around effective dates for PCI DSS requirements.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>

              <div className="expaneditems1 ">
                <Accordion >
                  <Accordion.Item className="my-3" eventKey="1">
                    <Accordion.Header>If I Am Using A Third-Party To Process Payments, Or An Ecommerce Platform, Do I Still Need To Worry About PCI Compliance ?</Accordion.Header>
                    <Accordion.Body>
                      Yes, even if some of the payment processes may reduce your risk of breach or what is in scope for PCI compliance, business cannot ignore it.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion >
                  <Accordion.Item className="my-3" eventKey="1">
                    <Accordion.Header>If I Only Accept Credit Cards Over The Phone, Does PCI DSS Still Apply To Me ?</Accordion.Header>
                    <Accordion.Body>
                      Yes. any processing, storing or transmitting of payment cardholder data needs to be done under a PCI Compliant environment.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion >
                  <Accordion.Item className="my-3" eventKey="1">
                    <Accordion.Header>How Do I Decide My PCI Compliance ‘Levels’ ?</Accordion.Header>
                    <Accordion.Body>
                      Merchants fall into four merchant levels based on Visa transactions over a 12 month period. The PCI compliance level can be decided by the number of transactions the merchants process each year.<br />
                      Level 1: Merchants that process over 6 million card transactions annually.<br />
                      Level 2: Merchants that process 1 to 6 million transactions annually.<br />
                      Level 3: Merchants that process 20,000 to 1 million transactions annually.<br />
                      Level 4: Merchants that process fewer than 20,000 transactions annually.<br />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>



              </div>

            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default SeviceCommon