import React from 'react'
 import Allpage from '../../components/all-page/Allpage'
import CommonSecuirityManageMent from './CommonServiceManagement'

function NetworkApplication() {
  return (
    <div>
    <div className="solutions_banner">
    {/* <div
      className="page-header__bg"
      style={{ backgroundImage: `url('../images/resources/aboutbg1.png')` }}
    ></div> */}
    <div className="container">
      <ul className="thm-breadcrumb list-unstyled">
        <li>
          <a href="/">Home</a>
        </li>
        <li>Cyber Security Management & Visibility solutions</li>
      </ul>
      <h2 className="page-header__title">	Data, Network & Application Management</h2>
    </div>
 
    </div>
 
    <section>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-8">
          <h2 className="text-center header">Cyber Security Management & Visibility solutions</h2>
 
 <img className='rounded mb-2' width="100%" height=" 350px"   src="/assets/images/solutions/Data,_Network_Application_Management.jpg" alt="img" />

 <p className='text-dark textJustify'>As a valued partner of iValue InfoSolutions Pvt. Ltd, "Right Time Limited" offers a comprehensive suite of Data, Network, and Application Management solutions to empower organizations with efficient and secure IT operations. These solutions are designed to streamline data handling, optimize network infrastructure, and enhance application performance while maintaining robust security measures. </p>
 
          </div>
          <div className='col-lg-4'>
            <CommonSecuirityManageMent/>
          </div>
        </div>
        <div className="textJustify">
          <p>Under the umbrella of Data Management, "Right Time Limited" provides advanced data encryption services to protect sensitive information at rest and in transit. Its data backup and recovery solutions ensure business continuity by safeguarding critical data from loss or damage. Additionally, their data archiving services enable efficient long-term data retention, ensuring compliance with regulatory requirements.  </p>
          <p> For Network Management, "Right Time Limited" offers cutting-edge network monitoring and performance optimization tools. These solutions provide real-time insights into network health, identifying bottlenecks and areas for improvement. Its network security offerings, such as firewalls and intrusion detection systems, bolster defenses against cyber threats, ensuring a secure network environment. </p>
          <p>In the realm of Application Management, "Right Time Limited" provides tools for application performance monitoring and optimization. These solutions allow organizations to identify and resolve performance issues, ensuring smooth and efficient application delivery. Its application security services protect against potential vulnerabilities, securing critical applications from exploitation.  </p>
          <p> The collaborative efforts of "Right Time Limited" and iValue InfoSolutions Pvt. Ltd enable organizations to achieve seamless data, network, and application management, thereby enhancing overall operational efficiency and cybersecurity. With a focus on efficiency, performance, and security, "Right Time Limited" empowers organizations to stay ahead in the dynamic IT landscape while safeguarding clients digital assets and ensuring a successful and secure business environment. </p>
  
          </div>
             </div>
 
         
 
    </section>
    
 
 
 
      
    <br/><br/><br/><br/><br/> </div>
 
  )
}

export default NetworkApplication
