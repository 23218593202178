import React from 'react'
import Allpage from '../../components/all-page/Allpage'

function WhitePapers() {
  
   return (
    <div>
   <div className="page-header">
   <div
     className="page-header__bg"
     style={{ backgroundImage: `url('../images/resources/aboutbg1.png')` }}
   ></div>
   <div className="container">
     <ul className="thm-breadcrumb list-unstyled">
       <li>
         <a href="/">Home</a>
       </li>
       <li>White Papers</li>
     </ul>
     <h2 className="page-header__title">White Papers</h2>
   </div>

   </div>

   <section>
     <div className="container mt-5">
       <h2 className="text-center header">White Papers</h2>
       <p className='text-dark'> Right Time Limited's white papers delve into in-depth analyses of various cybersecurity and IT management topics. These comprehensive documents explore industry trends, emerging threats, and strategic approaches to address complex security challenges. It provides valuable insights, research findings, and recommendations to help organizations stay ahead of evolving cybersecurity risks.
</p>
     
    
     </div>

     <h6 className='text-success mt-5 text-center'>More Information Coming Soon .....</h6>
       

   </section>
   



     
   <br/><br/><br/><br/><br/> </div>
 )
}

export default WhitePapers