import React from 'react'
 import Allpage from '../../components/all-page/Allpage'

function PrivillegeAccess() {
  return (
    <Allpage data="PAM (Privilege Access Management)"/>
 
  )
}

export default PrivillegeAccess
