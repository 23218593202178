import React from 'react'
import Allpage from '../../components/all-page/Allpage'

function DataSheet() {
  
   return (
    <div>
   <div className="page-header">
   <div
     className="page-header__bg"
     style={{ backgroundImage: `url('../images/resources/aboutbg1.png')` }}
   ></div>
   <div className="container">
     <ul className="thm-breadcrumb list-unstyled">
       <li>
         <a href="/">Home</a>
       </li>
       <li>Data Sheets</li>
     </ul>
     <h2 className="page-header__title">Data Sheets</h2>
   </div>

   </div>

   <section>
     <div className="container mt-5">
       <h2 className="text-center header">Data Sheets</h2>
       <p className='text-dark'> Right Time Limited's data sheets provide concise and specific information about various security solutions, services, and offerings. These documents offer a comprehensive overview of capabilities, features, and benefits, allowing organizations to make informed decisions about the most suitable solutions for their specific needs.
</p>
     
    
     </div>

     <h6 className='text-success mt-5 text-center'>More Information Coming Soon .....</h6>
       

   </section>
   



     
   <br/><br/><br/><br/><br/> </div>
 )
}

export default DataSheet