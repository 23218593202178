import React from 'react'
import Allpage from '../../components/all-page/Allpage'
import CommonSecuirityManageMent from './CommonServiceManagement'

function WorkFromHome() {
  return (
    <div>
      <div className="solutions_banner">
        {/* <div
      className="page-header__bg"
      style={{ backgroundImage: `url('../images/resources/aboutbg1.png')` }}
    ></div> */}
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Cyber Security Management & Visibility solutions</li>
          </ul>
          <h2 className="page-header__title">	Work From Home Solutions</h2>
        </div>

      </div>

      <section>
        <div className="container mt-5">
          <div className='row'>
            
            <div className='col-lg-8'>
              <h2 className="text-center header">	Work From Home Solutions</h2>
              <img className="rounded mb-2"width="100%" height="400px" src="/assets/images/solutions/Work_From_Home_Solutions.jpg" alt="img" />
              <div className="textJustify">
                <p>  As a partner of iValue InfoSolutions Pvt. Ltd, "Right Time Limited" offers comprehensive Work From Home Solutions to support organizations in transitioning to remote work environments effectively. These solutions are designed to enable seamless remote collaboration, enhance productivity, and ensure the security of data and communications. </p>
                
                </div>
              </div>
              <div className='col-lg-4'>
                <CommonSecuirityManageMent />
              </div>
            </div>
            <div className="textJustify">
            <p> "Right Time Limited's" Work From Home Solutions encompass a range of offerings that cater to the unique requirements of remote work setups. It provides secure and reliable remote access solutions, enabling employees of client/customer to connect to their corporate networks and access essential resources from anywhere securely.  </p>
                <p> Its communication and collaboration tools facilitate virtual meetings, team collaboration, and file sharing, ensuring smooth communication and efficient teamwork among remote employees. These solutions help organizations maintain productivity and foster a sense of connectivity among their distributed workforce.  </p>
            <p>  To ensure data security in remote work scenarios, "Right Time Limited" offers comprehensive cybersecurity solutions. These include endpoint protection, data encryption, and secure access controls, safeguarding sensitive information and mitigating potential cyber threats. </p>
            <p> Furthermore, "Right Time Limited" provides training and support to help organizations and employees adapt to remote work environments seamlessly. Its expertise and guidance empower businesses to address the challenges of remote work effectively, ensuring smooth operations and optimal productivity.  </p>
            <p>  By collaborating with iValue InfoSolutions Pvt. Ltd, "Right Time Limited" ensures that organizations have access to the right Work From Home Solutions for their specific needs. By leveraging these solutions, businesses can navigate the transition to remote work confidently, maintain business continuity, and enable its employees to work efficiently from any location while upholding the highest standards of data security. </p>
            </div>

          </div>



      </section>





      <br /><br /><br /><br /><br /> </div>

  )
}

export default WorkFromHome
