import React from 'react'
import CommonSecuirityManageMent from './CommonServiceManagement'

function DigitalAsset() {
  return (
    <div>
      <div className="solutions_banner">
        {/* <div className="page-header__bg"
          style={{ backgroundImage: `url('/assets/images/background/sol.png')` }}></div> */}
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li><a href="{{ url('/') }}">Home</a></li>
            <li><a href="{{ url('/services') }}">Cyber Security Management & Visibility solutions</a></li>
            <li>Digital Asset</li>
          </ul>
          <h2 className="page-header__title">Digital Asset  </h2>
        </div>
      </div>

      <section className="section-padding--bottom ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">


              <div className="blog-details__content">
                <div className='row'>
                  <div className='col-md-8 textJustify'>
                    <h3 className="blog-card-one__title blog-details__title text-center mt-5">Digital Asset (Security information and event management)</h3>

                    <img className='rounded mb-2' width="100%" height=" 400px" src="/assets/images/solutions/digital_asset_protection.jpg" alt="img" />

                    

                  </div>
                  <div className='col-md-4'>
                    <CommonSecuirityManageMent />
                  </div>

                </div>
                <div className='textJustify'>
                <p>As a partner of Value InfoSolutions Pvt. Ltd., "Right Time Limited" offers comprehensive Digital Asset Protection Solutions to their clients. These solutions are designed to safeguard an organization's digital assets, including data, applications, and critical information, from various cybersecurity threats.</p>
                    
                <p className='text-dark'>As a partner of Fortra, "Right Time Limited" provides its clients with the powerful security assessment solution, Core Impact. Core Impact is an advanced penetration testing and vulnerability assessment tool designed to help organizations evaluate and improve their cybersecurity defenses.</p>
                </div>
                <div className='text-dark mt-5 textJustify'>
                  
                  <h4 className='header'> Key features and benefits of Digital Asset Protection Solutions offered by "Right Time Limited":</h4>
                  <p><span className=" header" >Data Encryption:  </span>  We provide encryption solutions to protect sensitive data, ensuring that it remains secure both during storage and transmission.   </p>

                  <p><span className=" header" > Endpoint Security:  </span>  The solutions include endpoint protection tools to secure devices such as computers, laptops, and mobile devices from malware, unauthorized access, and data breaches.  </p>
                  <p><span className=" header" > Network Security:  </span>  We offer network security solutions to protect an organization's IT infrastructure from external threats, including firewalls, intrusion detection, and prevention systems.  </p>
                  <p><span className=" header" >  Data Loss Prevention:</span>   The solutions include data loss prevention tools that monitor and control data flow, preventing the unauthorized transmission of sensitive information outside the organization.  </p>
                  <p><span className=" header" > Identity and Access Management (IAM):  </span>  We provide IAM solutions to manage user access and authentication, ensuring that only authorized individuals can access critical resources.  </p>
                  <p><span className=" header" >Security Awareness Training:   </span> "Right Time Limited" offers security awareness training programs to educate employees about cybersecurity best practices and reduce the risk of human-related security breaches.   </p>
                  <p><span className=" header" > Incident Response and Recovery:  </span> The solutions include incident response and recovery plans to minimize the impact of cyber incidents and facilitate the organization's return to normal operations.   </p>
                  <p><span className=" header" >   Continuous Monitoring:</span>  Digital Asset Protection Solutions involve continuous monitoring of the IT environment, enabling proactive threat detection and swift responses to emerging cyber threats.  </p>


                </div>

                <p className='textJustify mt-2'> By leveraging "Right Time Limited's" Digital Asset Protection Solutions through their partnership with iValue InfoSolutions Pvt. Ltd., organizations can fortify their cybersecurity defenses, protect sensitive data, and maintain the integrity and confidentiality of their digital assets. The comprehensive suite of solutions covers various aspects of cybersecurity, allowing organizations to adopt a holistic approach to safeguarding their critical digital resources.</p>


              </div>


            </div>

          </div>


        </div>
      </section><br /><br /><br />
    </div>
  )
}

export default DigitalAsset
