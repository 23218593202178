import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Offer from "../../../components/services/vapt/Offer";
import { PtData,PtOfferCard } from "../../../components/services/vapt/ptData/PtData";
import { VaData ,VaOfferCard} from "../../../components/services/vapt/vaData/VaData";
import VaptFooter from "../../../components/services/vapt/VaptFooter";
import VaptHeader from "../../../components/services/vapt/VaptHeader";
  function VulnerabilityAssesment() {
    const [vaTap, setvaTap] = useState(201);
  const [PtTap, setPtTap] = useState(301);


  const [typeOfPtTab, settypeOfPtTab] = useState(0);
  const [PtApproachTap, setPtApproachTap] = useState(0);
  const [PtServiceTap, setPtServiceTap] = useState(0);

  const [vtTap, setvtTap] = useState(401);

  const [typeOfvtTab, settypeOfvtTab] = useState(0);
  const [vtApproachTap, setvtApproachTap] = useState(0);
  const [vtServiceTap, setvtServiceTap] = useState(0);

  const [style, setStyle] = useState("cont2");
  const [style2, setStyle2] = useState("cont");



  const [style3, setStyle3] = useState("cont");
  const [style4, setStyle4] = useState("cont2");
  const [style5, setStyle5] = useState("cont");


  const changeStyle = () => {
    console.log("you just clicked");
  
    setStyle("cont2");
  };

  console.log(typeOfPtTab, PtApproachTap, PtServiceTap);

  console.log("offer", PtData.type_of_pt);

  return (
    <div>


       <VaptHeader/>

      <section id="offer mt-4">
        <div className="container">
          <h2 className="header text-center">Our Offer</h2>
          <p className="text-dark mt-4">
            As a result, when it comes to testing, our testers thoroughly
            understand how the target systems have been built. This also applies
            not only to our application testing, but to our infrastructure and
            WiFi testing as well. Beyond the deep technical understanding and
            backgrounds that our testers have, we also have a detailed – and yet
            tailorable – methodologies to provide a consistent and high-quality
            results to our clients.
          </p>

          <Row className="mt-4">
            <Col md={4}>
              <div>
                <div className="d-flex">
                  <Button
                     onClick={() =>(
                      setvaTap(201), 
                      setPtTap(301),
                      setvtTap(401),
                      setStyle5("cont"),
                      setStyle3("cont"),
                      setStyle4("cont2"),
                        setStyle2("cont"),
                       setStyle("cont2")


                    ) }
                    className={style}

                  >
                    Vulnerability Assessment 
                  </Button>
                  <Button
                      onClick={() =>(
                        setvaTap(202),
                        setPtTap(301),
                        setvtTap(401),
                        setStyle("cont"),
                        setStyle2("cont2"),
                        setStyle4("cont2"),
                        setStyle3("cont"),
                        setStyle5("cont")




                      )}
                      className={style2}
 
                   >
                    Penetration Testing
                  </Button>
                </div>


                {vaTap === 201 && (
                  <div className="d-flex  ">
                    <Button
                      onClick={() => (setvtTap(401),    setStyle3("cont"),
                      setStyle4("cont2"),setStyle5("cont"))}
                     className={style4}
                    >
                      Types of VA
                    </Button>
                    <Button
                      onClick={() =>(setvtTap(402) ,setStyle4("cont"),
                      setStyle3("cont2"),setStyle5("cont"))}
                      className={style3}
                    >
                     VA Approaches
                    </Button>
                    <Button
                      onClick={() =>(setvtTap(403) ,setStyle4("cont"),
                     setStyle3("cont"),setStyle5("cont2"))}
                     className={style5}
                    >
                     ScenarioVA
                    </Button>
                  </div>
                )}




                {vaTap === 202 && (
                  <div className="d-flex ">
                    <Button
                       onClick={() => (setPtTap(301),    setStyle3("cont"),
                       setStyle4("cont2"),setStyle5("cont"))}
                      className={style4}
                    >
                      Types of PT
                    </Button>
                    <Button
                       onClick={() =>(setPtTap(302) ,setStyle4("cont"),
                       setStyle3("cont2"),setStyle5("cont"))}
                       className={style3}
                       >
                      PT Approaches
                    </Button>
                    <Button
                        onClick={() =>(setPtTap(303) ,setStyle4("cont"),
                        setStyle3("cont"),setStyle5("cont2"))}
                        className={style5}
                       >
                      PT Services
                    </Button>
                  </div>
                )}


                {vaTap===201?(
                    <div className="mt-2">
                    {vtTap === 401 &&
                      VaData?.type_of_va?.map((item, index) => (
                        <div className=" servicesItem">
                          <a
                            key={index}
                            style={{ textDecoration: "none" }}
                            className="text-dark   focus"
                            onClick={() => settypeOfvtTab(index)}
                            href="#javascript void(0)"
                          >
                            <img
                              src="/assets/images/services/initial.png"
                              alt=""
                            />
                            {item.title}
                          </a>
                        </div>
                      ))}
  
                    {vtTap === 402 &&
                      VaData?.va_approach?.map((item, index) => (
                        <div className=" servicesItem">
                          <a
                            key={index}
                            style={{ textDecoration: "none" }}
                            className="text-dark focus"
                            onClick={() => setvtApproachTap(index)}
                            href="#javascript void(0)"
                          >
                            <img
                              src="/assets/images/services/initial.png"
                              alt=""
                            />
                            {item.title}
                          </a>
                        </div>
                      ))}
  
                    {vtTap === 403 &&
                      VaData?.va_service?.map((item, index) => (
                        <div className=" servicesItem">
                          <a
                            key={index}
                            style={{ textDecoration: "none" }}
                            className="text-dark   focus"
                            onClick={() => setvtServiceTap(index)}
                            href="#javascript void(0)"
                          >
                            <img
                              src="/assets/images/services/initial.png"
                              alt=""
                            />
                            {item.title}
                          </a>
                        </div>
                      ))}
                  </div>
                ):(
                  <div className="mt-2">
                  {PtTap === 301 &&
                    PtData?.type_of_pt?.map((item, index) => (
                      <div className=" servicesItem">
                        <a
                          key={index}
                          style={{ textDecoration: "none" }}
                          className="text-dark   focus"
                          onClick={() => settypeOfPtTab(index)}
                          href="#javascript void(0)"
                        >
                          <img
                            src="/assets/images/services/initial.png"
                            alt=""
                          />
                          {item.title}
                        </a>
                      </div>
                    ))}

                  {PtTap === 302 &&
                    PtData?.pt_approach?.map((item, index) => (
                      <div className=" servicesItem">
                        <a
                          key={index}
                          style={{ textDecoration: "none" }}
                          className="text-dark focus"
                          onClick={() => setPtApproachTap(index)}
                          href="#javascript void(0)"
                        >
                          <img
                            src="/assets/images/services/initial.png"
                            alt=""
                          />
                          {item.title}
                        </a>
                      </div>
                    ))}

                  {PtTap === 303 &&
                    PtData?.pt_service?.map((item, index) => (
                      <div className=" servicesItem">
                        <a
                          key={index}
                          style={{ textDecoration: "none" }}
                          className="text-dark   focus"
                          onClick={() => setPtServiceTap(index)}
                          href="#javascript void(0)"
                        >
                          <img
                            src="/assets/images/services/initial.png"
                            alt=""
                          />
                          {item.title}
                        </a>
                      </div>
                    ))}
                </div>
                )}

               
              </div>
            </Col>




            <Col md={8}>
              {
                vaTap === 201?(
                  <div>
                  {vtTap === 401 && typeOfvtTab === typeOfvtTab && (
                    <Offer Offercard={VaOfferCard} id={typeOfvtTab} va="va" data={VaData?.type_of_va} />
                  )}
  
                  {vtTap ===402 && vtApproachTap === vtApproachTap && (
                    <Offer Offercard={VaOfferCard} id={vtApproachTap} va="va" data={VaData?.va_approach} />
                  )}
  
                  {vtTap === 403 && vtServiceTap === vtServiceTap && (
                    <Offer Offercard={VaOfferCard} id={vtServiceTap} va="va" data={VaData?.va_service} />
                  )}
                </div>

                ):(





                  <div>
                  {PtTap === 301 && typeOfPtTab === typeOfPtTab && (
                    <Offer Offercard={PtOfferCard} pt="pt" id={typeOfPtTab} data={PtData?.type_of_pt} />
                  )}
  
                  {PtTap  === 302 && PtApproachTap === PtApproachTap && (
                    <Offer Offercard={PtOfferCard} pt="pt" id={PtApproachTap} data={PtData?.pt_approach} />
                  )}
  
                  {PtTap === 303 && PtServiceTap === PtServiceTap && (
                    <Offer Offercard={PtOfferCard} pt="pt" id={PtServiceTap} data={PtData?.pt_service} />
                  )}
                </div>
                )
              }
              
            </Col>
          </Row>
        </div>
      </section>

      <VaptFooter/>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default VulnerabilityAssesment;
