import React from 'react'
import './career.css'
import Accordion from 'react-bootstrap/Accordion'
import { Row,Col } from 'react-bootstrap'
function ViewJob() {
  return (
    <div>
      <div className="page-header">
        <div className="page-header__bg"
          style={{ backgroundImage: `url('https://www.krg.ca/en-CA/assets/Careers-new.jpg')` }}></div>
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li><a href="/">Home</a></li>
            <li>Careers</li>
          </ul>
          <h2 className="page-header__title">Careers</h2>
        </div>
      </div>

      <div className="section-title text-center mt-5 container">
            <p className="section-title__text">Careers</p>
            <h2 className=" ">We’re a growing company.</h2>
            <p>Right Time Limited is a leading provider of SOC-as-a-Service (Cyber Security Operations Center – SOC) for the USA,Bangladesh, Australia, and the jarmany Cyber Security market. SafeAeon currently provides security SOC services to many Fortune 1000 companies in collaboration with WaveStrong Inc. and Marseon Inc., which are both US Silicon-Valley based Cyber Security companies.</p>
          </div>

 
      <div className="page-header ">
        <div className="page-header__bg"
          style={{ backgroundImage: `url('/assets/images/career/job.svg')` }}></div>
        <div className="container text-center">
          <h2 className="page-header__title"> Senior QA Automation Engineer </h2>
          <a href="/apply-job" className=" mt-5 thm-btn cta-two__btn rounded-5">
                    <span>Apply Now<i className="mx-3 fas fa-arrow-right"></i></span> 
                  </a>
        </div>
      </div>

      <section >
        <div className='container mt-5'>

          <div>

            <h5>Position Title: <span style={{color:"gray",fontSize:"18px"}}>Senior QA Automation Engineer</span></h5>
          <h5 className='mt-3'>Location: <span style={{color:"gray",fontSize:"18px"}}>DBDL Building ,kawran Bazar,Dhaka</span></h5>
          <h5 className='mt-3'>Key Skill: <span style={{color:"gray",fontSize:"18px"}}>BDD, Selenium, Java, Testing, Agile</span></h5>

          </div>

          <div className='mt-5'>
            <h5>
           <a href='/about'>Who Are We?</a> 
            </h5>
            <p>Bottomline are aligned to lead the business payments industry by removing complexity, and uniquely addressing the changing needs of how businesses pay and get paid.</p>
          <p className='mt-3'>A journey that goes around the world serving financial institutions and companies in more than 90 countries, head quartered in Portsmouth, New Hampshire US, with offices situated across Europe and Asia.</p>
          <p className='mt-3'>This role is based in Kosovo, the office being set in the heart of Prishtinë embassy territory. The teams deliver critical development with our emergent technologies and products, facilitating growth in both customer delight and new markets. Our mission for Prishtinë allows us to continuously learn, innovate, facilitate, and motivate knowledge sharing together while attracting the best talent in Kosovo and its neighbouring countries.</p>
          </div>


          <div className='mt-5'>
            <h5>
           <a href='/about'>Culture and Values</a> 
            </h5>
            <p>We are one global team, creating customer delight, who are strongly committed to maintaining our culture, and ensuring our values and principles are lived out daily. We empower each other to think like owners, building a company of which we can all be proud of and produce results collaboratively.</p>
          <p className='mt-3'>Strongly committed to sharing ideas, innovating, and supporting one another, both personally and professionally on our global business payments journey. We are in it together, tried, and true.</p>
           </div>


           <div className='mt-5'>
            <h5>
           <a href='/about'>Role</a> 
            </h5>
            <p>Financial Messaging is the mission critical function to securely communicate, reconcile, and manage the data in financial transactions within and between banks, both locally and internationally.</p>
          <p className='mt-3'>As a Senior QA Automation Engineer, you will have the exciting opportunity to make a difference in quality initiatives for our market leading FM team who are located across central Europe. BT use scrum-based development processes that provide estimates and meeting the commitments required for success.</p>
           </div>


           <div className='mt-5'>
            <h5>
           <a href='/about'>Responsibilities</a> 
            </h5>
            <p> <i   className="fas fa-circle iconStyle "> </i> Hands-on involvement in a project team and be accountable for requirement test planning & estimation, execution, and retrospective analysis</p>
             <p className='mt-3'>  <i className="fas fa-circle iconStyle "> </i> Identify opportunities for automation within software processes</p>
             <p> <i   className="fas fa-circle iconStyle "> </i> Work closely with developers, scrum masters, and product owners to build product test frameworks, test strategies, lead automation implementation for front-end web apps, microservices</p>
             <p className='mt-3'>  <i className="fas fa-circle iconStyle "> </i> Design and execute QA tests using scripts that automatically test functionality</p>
             <p> <i   className="fas fa-circle iconStyle "> </i> Identify and report bugs and quality issues</p>
             <p className='mt-3'>  <i className="fas fa-circle iconStyle "> </i> Identify opportunities for automation within software processes</p>
             <p> <i   className="fas fa-circle iconStyle "> </i>Assist with manual testing as necessary  </p>
             <p> <i   className="fas fa-circle iconStyle "> </i> Provide technical guidance to less experienced QAAs </p>
             <p> <i   className="fas fa-circle iconStyle "> </i> Any other task required by the team to deliver good quality product to customers </p>
        
              
           </div>



           <div className='mt-5'>
            <h5>
           <a href='/about'>Essential Experience</a> 
            </h5>
              <p> <i   className="fas fa-circle iconStyle "> </i> University Degree or Equivalent </p>
             <p> <i   className="fas fa-circle iconStyle "> </i> Proven experience in automation framework development for Web application </p>
             <p> <i   className="fas fa-circle iconStyle "> </i> Technical knowledge on automation using Java, Rest-Assured, Selenium, Gatling, Jenkins </p>
             <p> <i   className="fas fa-circle iconStyle "> </i> Working knowledge and experience with JIRA, Confluence and Linux is must </p>
             <p> <i   className="fas fa-circle iconStyle "> </i>Ability to read, write and analyse SQL database queries (Oracle)  </p>
             <p> <i   className="fas fa-circle iconStyle "> </i> Test reporting knowledge using TestNG and other tools </p> 
              <p> <i   className="fas fa-circle iconStyle "> </i>  RESTful API in automating webservices using serenity</p>
             <p> <i   className="fas fa-circle iconStyle "> </i> Experience in working with CI/CD integrations in Gitlab/Jenkins </p>

              
           </div>
           <div>
           <a href="/apply-job" className=" mt-5 thm-btn cta-two__btn rounded-5">
                    <span>Apply Now <i className="mx-3 fas fa-arrow-right"></i></span> 
                  </a>
           </div>
          


        </div>



      </section>



     





{/* 


      <div className=" mt-5">
        </div>
        <section className="     m-5">
          <div className=" ">
          <div className="section-title text-center">
            <p className="section-title__text">Careers</p>
            <h2 className=" ">We’re a growing company.</h2>
            <p>Right Time Limited is a leading provider of SOC-as-a-Service (Cyber Security Operations Center – SOC) for the USA,Bangladesh, Australia, and the jarmany Cyber Security market. SafeAeon currently provides security SOC services to many Fortune 1000 companies in collaboration with WaveStrong Inc. and Marseon Inc., which are both US Silicon-Valley based Cyber Security companies.</p>
          </div>

          <Row>
            <Col md={4}>
            <img
            width="100%"
              className = "img-responsive"
              src= "/assets/images/career/job.png"
              alt=""
            />
            </Col>

            <Col md={8}  >
              <div style={{paddingTop:"80px"}} className='mt-5'>
                <Accordion defaultActiveKey={['1']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header><h6 >Marketing</h6></Accordion.Header>
                <Accordion.Body>
                  <a href='/marketing'>
                  <h6>Account Based Marketing Executive</h6>
                  <h6 className='text-info'>Madison, WIS, USA</h6>
                 </a>

                 <a className='mt-5' href='/marketing'>
                  <h6>Account Based Marketing Executive</h6>
                  <h6 className='text-info'>Madison, WIS, Bangladesh</h6>
                 </a>
                 <a className='mt-5' href='/marketing'>
                  <h6>Account Based Marketing Executive</h6>
                  <h6 className='text-info'>Madison, WIS, Jarmany</h6>
                 </a>
                 <a className='mt-5' href='/marketing'>
                  <h6>Account Based Marketing Executive</h6>
                  <h6 className='text-info'>Madison, WIS, Australia</h6>
                 </a>
                  
                </Accordion.Body>
              </Accordion.Item>
              
              <Accordion.Item eventKey="2">
                <Accordion.Header><h6 >Presales Engineering</h6></Accordion.Header>
                <Accordion.Body>
                  <a href='/marketing'>
                  <h6>Senior Presales Engineer</h6>
                  <h6 className='text-info'>Madison, WIS, USA</h6>
                 </a>

                 <a className='mt-5' href='/marketing'>
                  <h6>Senior Presales Engineer</h6>
                  <h6 className='text-info'>Madison, WIS, Bangladesh</h6>
                 </a>
                 <a className='mt-5' href='/marketing'>
                  <h6>Senior Presales Engineer</h6>
                  <h6 className='text-info'>Madison, WIS, Jarmany</h6>
                 </a>
                 <a className='mt-5' href='/marketing'>
                  <h6>Senior Presales Engineer</h6>
                  <h6 className='text-info'>Madison, WIS, Australia</h6>
                 </a>
                  
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header><h6 >R & D</h6></Accordion.Header>
                <Accordion.Body>
                  <a href='/marketing'>
                  <h6>Senior Threat Intelligence Analyst</h6>
                  <h6 className='text-info'>Madison, WIS, USA</h6>
                 </a>

                 <a className='mt-5' href='/marketing'>
                  <h6>Senior Threat Intelligence Analyst</h6>
                  <h6 className='text-info'>Madison, WIS, Bangladesh</h6>
                 </a>
                 <a className='mt-5' href='/marketing'>
                  <h6>Senior Threat Intelligence Analyst</h6>
                  <h6 className='text-info'>Madison, WIS, Jarmany</h6>
                 </a>
                 <a className='mt-5' href='/marketing'>
                  <h6>Senior Threat Intelligence Analyst</h6>
                  <h6 className='text-info'>Madison, WIS, Australia</h6>
                 </a>
                  
                </Accordion.Body>
              </Accordion.Item>
 
              <Accordion.Item eventKey="4">
                <Accordion.Header><h6 >Security Service</h6></Accordion.Header>
                <Accordion.Body>
                  <a href='/marketing'>
                  <h6>Senior SOC Analysts</h6>
                  <h6 className='text-info'>Madison, WIS, USA</h6>
                 </a>

                 <a className='mt-5' href='/marketing'>
                  <h6>Senior SOC Analysts</h6>
                  <h6 className='text-info'>Madison, WIS, Bangladesh</h6>
                 </a>
                 <a className='mt-5' href='/marketing'>
                  <h6>Senior SOC Analysts</h6>
                  <h6 className='text-info'>Madison, WIS, Jarmany</h6>
                 </a>
                 <a className='mt-5' href='/marketing'>
                  <h6>Senior SOC Analysts</h6>
                  <h6 className='text-info'>Madison, WIS, Australia</h6>
                 </a>
                  
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            
              </div>
            </Col>
            
           </Row>
    


          </div>
        </section> */}


      




      <br /><br /><br /> 

    </div>
  )
}

export default ViewJob
