export const data = [
    {
        id: 1,
        title: "SOC as A Service ",
        description :" ",
        url: "/soc-as-service ",	

    },
    {
        id: 2,
        title: "Cloud App Monitoring as A Service ",
        description :"Cloud App Monitoring as A Service ",
        url: "/cloud-app",	

    },

    {
        id: 3,
        title: "MDR as A Service ",
        description :" ",
        url: "/mdr-as-service ",	

    },

    {
        id: 4,
        title: " Managed Nextgen Firewall as A Service ",
        description :" ",
        url: "/managed-nextgen ",	

    },

    {
        id: 5,
        title: " Vulnerability Assessment (VA) As A Service ",
        description :" ",
        url: "/va-as-service ",	

    },

    {
        id: 6,
        title: " Penetration Testing (PT) as a Service ",
        description :" ",
        url: "/pt-as-service ",	

    },

    {
        id: 7,
        title: " DAM as a Service ",
        description :" ",
        url: "/dam-as-service ",	

    },

    {
        id:8,
        title: " Attack Surface Management ",
        description :" ",
        url: "/Attact-surface ",	

    },


    
]