import React from 'react'
import Allpage from '../../components/all-page/Allpage'

function Webinars() {
  
   return (
    <div>
   <div className="page-header">
   <div
     className="page-header__bg"
     style={{ backgroundImage: `url('../images/resources/aboutbg1.png')` }}
   ></div>
   <div className="container">
     <ul className="thm-breadcrumb list-unstyled">
       <li>
         <a href="/">Home</a>
       </li>
       <li>Webinars</li>
     </ul>
     <h2 className="page-header__title">Webinars</h2>
   </div>

   </div>

   <section>
     <div className="container mt-5">
       <h2 className="text-center header">Webinars</h2>
       <p className='text-dark'> Right Time Limited's webinars provide interactive sessions conducted by Its experts, covering a wide range of cybersecurity and IT management topics. These live or pre-recorded webinars offer participants the opportunity to learn from industry professionals, gain insights, and ask questions, fostering knowledge sharing and professional development.
</p>
     
    
     </div>

     <h6 className='text-success mt-5 text-center'>More Information Coming Soon .....</h6>
       

   </section>
   



     
   <br/><br/><br/><br/><br/> </div>
 )
}

export default Webinars