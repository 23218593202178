export const SwiftOfferData = [
    {
        id: 1,
        title: "Initial study (Business Understanding)",
        description:
          "Perform an initial study of business to understand your card processes, the environment and accordingly combine the PCI scope.",
        image: "assets/images/services/cardicon/1.png",
        header: "PCI DSS",
        message: "out of scope",
      },
      {
        id: 2,
        title: "Scope Finalization ",
        description:
          " Confirm systems that fall under the PCI DSS scope and articulate the scope statement.",
        image: "assets/images/services/cardicon/2.png",
        header: "PCI DSS",
        message: "out of scope",
      },
  
      {
        id: 3,
        title: "Gap Analysis (Readiness  Assessment) ",
        description:
          "Identify gaps including VA & PT (internal & External) in your organization’s security controlsystems and environment vis-à-vis PCI DSS requirements",
        image: "assets/images/services/cardicon/3.png",
        header: "PCI DSS",
        message: "out of scope",
      },
  
      {
        id: 4,
        title: "Data Flow/ Leakage Assessment",
        description:
          "Perform a thorough Data Flow/Leakage   assessment of your application and assist in remediation.",
        image: "assets/images/services/cardicon/4.png",
        header: "PCI DSS",
        message: "out of scope",
      },
      {
        id: 5,
        title: "Awareness & Implementation Training",
        description:
          " Confirm systems that fall under the PCI DSS scope and articulate the scope statement.",
        image: "assets/images/services/cardicon/5.png",
        header: "PCI DSS",
        message: "out of scope",
      },
      {
        id: 6,
        title: "Data & Assets Classification",
        description:
          " Assist Identifying your information assets across the organization and assist clients/customers classifying them as per criticality",
        image: "assets/images/services/cardicon/6.png",
        header: "PCI DSS",
        message: "out of scope",
      },
      {
        id: 7,
        title: "Risk Assessment  ",
        description:
          " Perform risk assessment to identify assets exposed to risk and assess how it could impact your organization",
        image: "assets/images/services/cardicon/7.png",
        header: "PCI DSS",
        message: "out of scope",
      },
  
      {
        id: 8,
        title: "Remediation(Risk Treatment)",
        description:
          "Perform an initial study of business to understand your card processes, the environment and accordingly combine the PCI scope.",
        image: "assets/images/services/cardicon/8.png",
        header: "PCI DSS",
        message: "out of scope",
      },
      {
        id: 9,
        title: "Technical Documentation Support ",
        description:
          " Confirm systems that fall under the PCI DSS scope and articulate the scope statement.",
        image: "assets/images/services/cardicon/9.png",
        header: "PCI DSS",
        message: "out of scope",
      },
  
      {
        id: 10,
        title: "Support for Policy Role Out",
        description:
          "Identify gaps including VA & PT (internal & External) in your organization’s security controlsystems and environment vis-à-vis PCI DSS requirements",
        image: "assets/images/services/cardicon/10.png",
        header: "PCI DSS",
        message: "out of scope",
      },
  
      {
        id: 11,
        title: "Pre-Assessment",
        description:
          "Perform an initial study of business to understand your card processes, the environment and accordingly combine the PCI scope.",
        image: "assets/images/services/cardicon/11.png",
        header: "PCI DSS",
        message: "out of scope",
      },
      {
        id: 12,
        title: "Evidence Review ",
        description:
          " Confirm systems that fall under the PCI DSS scope and articulate the scope statement.",
        image: "assets/images/services/cardicon/12.png",
        header: "PCI DSS",
        message: "out of scope",
      },
  
      {
        id: 13,
        title: "Audit & Attestation",
        description:
          "Identify gaps including VA & PT (internal & External) in your organization’s security controlsystems and environment vis-à-vis PCI DSS requirements",
        image: "assets/images/services/cardicon/6.png",
        header: "PCI DSS",
        message: "out of scope",
      },
      {
        id: 14,
        title: "Continuous Compliance Support",
        description:
          "Identify gaps including VA & PT (internal & External) in your organization’s security controlsystems and environment vis-à-vis PCI DSS requirements",
        image: "assets/images/services/cardicon/7.png",
        header: "PCI DSS",
        message: "out of scope",
      },
]