import React from 'react'
import CommonSecuirityAssesment from './CommonSecuirityAssesment'

function Tenable() {
  return (
    <div>
      <div className="solutions_banner">
        {/* <div
          className="page-header__bg"
          style={{ backgroundImage: `url('../images/resources/aboutbg1.png')` }}
        ></div> */}
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Security Assessment (VA & PT) Tools</li>
          </ul>
          <h2 className="page-header__title">                        Tenable (Multiple Solutions)
          </h2>
        </div>

      </div>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-8">
            <h2 className="text-center header">                        Tenable (Multiple Solutions)
          </h2>

          <div className="row mt-3">
        
            <div className='col-md-12'>
              <p className="text-dark">
                As a partner of Tenable, "Right Time Limited" offers its clients access to Tenable's comprehensive security assessment solutions. Tenable is a leading provider of cybersecurity products that help organizations proactively identify and address potential vulnerabilities in their IT infrastructure.
              </p>
              <img className='rounded' width="90%" src="/assets/images/solutions/Tenable.jpg" alt="img" />
            </div>
          </div>
            </div>
            <div className='col-lg-4'>
              <CommonSecuirityAssesment />
            </div>
          </div>

          <div className='text-dark textJustify'>
            <h4 className='header mt-5'>Key features and benefits of Tenable Security Product:</h4>
            <p><span className=" header" >     Vulnerability Scanning:  </span>Tenable's products conduct thorough vulnerability scans of an organization's networks, systems, and applications, identifying potential security weaknesses and exposures. </p>
            <p><span className=" header" >  Continuous Monitoring:   </span> The solutions enable continuous monitoring, ensuring that organizations can stay vigilant against evolving threats and newly discovered vulnerabilities.  </p>
            <p><span className=" header" >    Asset Discovery: </span> Tenable's products help clients discover and track all assets within their IT environment, allowing for better visibility and control over their security landscape.  </p>
            <p><span className=" header" > Compliance Management:    </span>  Tenable assists organizations in meeting compliance requirements by providing tools and reports that align with industry standards and regulations. </p>
            <p><span className=" header" >    Risk Prioritization: </span>  Tenable's solutions prioritize identified vulnerabilities based on their severity and potential impact, aiding organizations in allocating resources effectively for remediation efforts. </p>
            <p><span className=" header" >   Centralized Management:   </span> The products offer centralized management and reporting capabilities, streamlining security assessments and providing a holistic view of an organization's security posture. </p>
            <p><span className=" header" >  Remediation Guidance:   </span> Tenable provides actionable remediation guidance, helping organizations address identified vulnerabilities effectively.  </p>
            <p><span className=" header" >    Integration Capabilities: </span> Tenable's products can be integrated into existing security workflows and systems, enhancing overall security operations.  </p>


          </div>

          <p className='text-dark textJustify'>By leveraging Tenable's security assessment solutions, "Right Time Limited" empowers its clients to proactively defend against cyber threats. The comprehensive scanning, monitoring, and reporting capabilities of Tenable's products enable organizations to strengthen their cybersecurity posture, protect sensitive data, and maintain a secure digital environment. As a result, "Right Time Limited" helps its clients achieve a robust and resilient security framework to safeguard their IT infrastructure from potential security risks.</p>

        </div>



      </section>





      <br /><br /><br /><br /><br /> </div>
  )
}

export default Tenable