export const assessmentData = [

  {
    "id": 13,
    "title": "Certified Penetration Testing Professional (CPENT)",
    "link": "/penetration-testing"
  },
  {
    "id": 14,
    "title": "Offensive Security Certified Professional (OSCP)",
    "link": "/Offensive"
  },
  {
    "id": 15,
    "title": "Certified Information system Auditor (CISA)",
    "link": "/certified-information"
  },
  {
    "id": 16,
    "title": "Computer Hacking Forensic Investigation (Custom)",
    "link": "/computer-hacking"
  },
  {
    "id": 17,
    "title": "GIAC Penetration Testing (GPEN)",
    "link": "/giac-penetration"
  },
  {
    "id": 18,
    "title": "GIAC Web Application Penetration Testing (GWAPT)",
    "link": "/giac-web-application"
  }
]


export const ManagementData = [
  {
    "id": 19,
    "title": "Certified Ethical Hacking (CEH)",
    "link": "/practical-hacker"
  },
  {
    "id": 20,
    "title": "Certified Disaster Recovery Professional (EDRP)",
    "link": "/certified-disaster"
  },
  {
    "id": 21,
    "title": "Certified Incident Handler (ECIH)",
    "link": "/certified-incident-handler"
  },
  {
    "id": 22,
    "title": "Certified SOC Analyst (CSA)",
    "link": "/certified-soc-analyst"
  },
  {
    "id": 23,
    "title": "Certified Threat Intelligent Analyst (CTIA)",
    "link": "/certified-threat"
  },
  {
    "id": 24,
    "title": "Certified Information security Manager (CISM)",
    "link": "/certified-information-security"
  },
  {
    "id": 25,
    "title": "Certified Information System Security Professional (CISSP)",
    "link": "/certified-information-system-security"
  },
  {
    "id": 26,
    "title": "GIAC Certified Incident Handler (GCIH)",
    "link": "/giac-certified-incident"
  },
  {
    "id": 27,
    "title": "GIAC Certified Project Manager (GCPM)",
    "link": "/giac-certified-project"
  },
  {
    "id": 28,
    "title": "Open-Source Intelligence (OSINT)",
    "link": "/open-source"
  }

]

export const customizedData =
  [
    {
      "id": 29,
      "title": "Foundation track - (Corporate)",
      "link": "/foundation-track"
    },
    {
      "id": 30,
      "title": "Network Defense and Operations - (Corporate)",
      "link": "/network-defense-operations"
    },
    {
      "id": 31,
      "title": "Software Security - (Corporate)",
      "link": "/software-security"
    },
    {
      "id": 32,
      "title": "Vulnerability Assessment & Penetration Testing (VA & PT) - (Corporate)",
      "link": "/vulnerability-assessment-&-penetration-testing"
    },
    {
      "id": 33,
      "title": "Cyber Forensic - (Corporate)",
      "link": "/cyber-forensic"
    },
    {
      "id": 34,
      "title": "Governance - (Corporate)",
      "link": "/governance-corporate"
    }
  ]
