import React from 'react'
 import Allpage from '../../components/all-page/Allpage'

function SmartContact() {
  return (
    <Allpage data="Patch Management"/>
 
  )
}

export default SmartContact
