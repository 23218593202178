export const solutionData1 = [
    
      {
        "id": 2,
        "title": "Burp Suite (web PT)",
        "link": "/burp-suite"
      },
      {
        "id": 3,
        "title": "Net Sparker (web assessment)",
        "link": "/netsparker"
      },
      {
        "id": 4,
        "title": "Tenable (Multiple Solutions)",
        "link": "/tenable-sltn"
      },
      {
        "id": 5,
        "title": "Acunetix (web VA & PT)",
        "link": "/acunetix"
      },
      {
        "id": 6,
        "title": "Core Impact (PT)",
        "link": "/core-impact"
      }
]

export const solutionData2 = [
    
      {
        "id": 8,
        "title": "Digital Asset Protection",
        "link": "/digital-asset"
      },
      {
        "id": 9,
        "title": "Data, Network & Application Management",
        "link": "/network-application"
      },
      {
        "id": 10,
        "title": "Digital Transformation Solutions",
        "link": "/digital-transformation"
      },
      {
        "id": 11,
        "title": "Work From Home Solutions",
        "link": "/work-from-home"
      },
      {
        "id": 12,
        "title": "Dx Stack for ALM & Infrastructure",
        "link": "/stack-for-alm"
      }
]