export const penetrationtestingData =
{
    id: 1,
    body: `<h3 class="mt-3" style=" border-right: 1px solid rgb(55, 125, 255); margin-bottom: 0.5rem; line-height: 1.2; color: rgb(55, 71, 79); font-size: 1.75rem; font-family: Roboto, Arial, Helvetica; background-color: rgb(255, 255, 255); margin-top: 1rem !important"><span class="blog-card-one__title blog-details__title changeColor">Certified Penetration Testing Professional (CPENT)</span></h3> <div class="blog-details__content" style="color: rgb(55, 71, 79); font-family: Roboto, Arial, Helvetica; font-size: 16px; background-color: rgb(255, 255, 255);"> <p>Penetration Testing Professional (PTP) is an advanced-level course designed to equip cybersecurity professionals with the skills and knowledge needed to perform comprehensive penetration tests on networks, systems, and applications. The course focuses on hands-on training and practical exercises to simulate real-world scenarios, making it suitable for penetration testers, ethical hackers, and security professionals seeking to advance their penetration testing capabilities.</p> 
    <img class="training-images " src="/assets/images/training/CPENT.jpg"
    </div> `,
    trainItem: [
        {
            id: 1,
            title: "sd",
            bodyLeft: "d",
            bodyRight: "d",
        }
    ]
}

