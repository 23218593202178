export const resoursesData = [
    {
        id: 1,
        title: 'Blogs',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
    ]

    },
    {
        id: 2,
        title: 'Case Studies',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
    ]

    },
    {
        id: 3,
        title: 'Checklists',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
    ]

    },
    {
        id: 4,
        title: 'WhitePapers',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
    ]

    },
    {
        id: 5,
        title: 'Infographics        ',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
    ]

    },
    {
        id: 6,
        title: 'DataSheets        ',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
    ]

    },
    {
        id: 7,
        title: 'DataSheets        ',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
    ]

    },
    {
        id: 8,
        title: 'Webinars        ',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        
    ]

    },
    {
        id: 9,
        title: 'Documents        ',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        
    ]

    },
    {
        id: 10,
        title: 'Reports        ',
        description: 'KirkpatrickPrice experts dive deep into industry best practices and auditing topics.  Watch the recaps to strengthen your security and compliance programs.',
        resourcesContent: [
            {
            id: 1,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 2,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 3,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        {
            id: 4,
            title: 'Data Security for Generative AI: A Webinar Recap',
            date: '07 June 2021',
            image: 'https://kirkpatrickprice.com/wp-content/uploads/2022/12/0051-BlogPhotoBlueOrange-750x392.jpg',
        },
        
    ]

    },
    

]