import React from "react";

function Solution() {
  return (
    <div className="">
      <section className="cta-one">
        <div className="container">
          <div
            className="cta-one__inner text-center wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="cta-one__circle"></div>
            <div className="section-title ">
              <p className="section-title__text">Need Any Technology Solution</p>
              <h2 className="header header--light">
                Let’s work together for better services
              </h2> 
            </div>
            <a href="/contact-us" className="thm-btn thm-btn--light cta-one__btn">
              <span>Start Now</span>
            </a>
          </div>
        </div>
      </section><br/><br/>
    </div>
  );
}

export default Solution;
