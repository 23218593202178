import React from 'react'
 
function SolutionNav() {
    return (
        <div>
            <h3 className="sidebar__title">Solutions</h3>
            <ul className="sidebar__category">
                <li><a href="{{ url('/services/solutions/BURP-Suite') }}">BURP Suite</a></li>
                <li><a href="{{ url('/services/solutions/acunetix') }}">Acunetix</a></li>
                <li><a href="{{ url('/services/solutions/net-sporker') }}">Net Sparker</a></li>
                <li><a href="{{ url('/services/solutions/core-impact') }}">Core Impact</a></li>
                <li><a href="{{ url('/services/solutions/SIEM-solutions') }}">SIEM Solutions</a></li>
                <li><a href="{{ url('/services/solutions/firewall') }}">Firewall</a></li>
                <li><a href="{{ url('/services/solutions/Bulk-SMS') }}">Bulk SMS</a></li>
                <li><a href="{{ url('/services/solutions/smart-contract') }}">Smart Contract</a></li>
            </ul>

        </div>
    )
}

export default SolutionNav
