export const data = [
    {
        id: 1,
        title: 'Consultation',
        logo: 'assets/images/customer/1.png',
        
    },
    {
        id: 2,
        title: 'Consultation',
        logo: 'assets/images/customer/2.jpg',
        
    },
    {
        id: 3,
        title: 'Consultation',
        logo: 'assets/images/customer/3.webp',
    },
    {
        id: 4,
        title: 'Consultation',
        logo: 'assets/images/customer/4.png',
    },
    {
        id: 5,
        title: 'Consultation',
        logo: 'assets/images/customer/5.png',
    },
    {
        id: 6,
        title: 'Consultation',
        logo: 'assets/images/customer/6.jpg',
    },
    {
        id: 7,
        title: 'Consultation',
        logo: 'assets/images/customer/7.png',
    },
    {
        id: 8,
        title: 'Consultation',
        logo: 'assets/images/customer/8.jpg',
    },
    {
        id: 9,
        title: 'Consultation',
        logo: 'assets/images/customer/9.png',
    },
    {
        id: 10,
        title: 'Consultation',
        logo: 'assets/images/customer/10.jpg',
    },
    
    {
        id: 11,
        title: 'Consultation',
        logo: 'assets/images/customer/11.jpg',
    },
    
    {
        id: 12,
        title: 'Consultation',
        logo: 'assets/images/customer/12.png',
    },
    
    
    

]
