import React,{ useState} from 'react'
import IndustriesNav from './IndustriesNav'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
function HealthCare() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div className="page-header">
        <div className="page-header__bg"
          style={{ backgroundImage: `url('/assets/images/background/health.png')` }}></div>

        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li><a href="/">Home</a></li>
            <li>Health Care</li>
          </ul>
          <h2 className="page-header__title">Health Care</h2>
        </div>
      </div>

      <section className="section-padding--bottom section-padding--top">
        <div className="container">
          <div className="row">
          <div className="col-lg-4">
              <div className="sidebar">
                <div className=" ">
                  <IndustriesNav title="health" />

                </div>
              </div>
            </div>
            <div className="col-lg-8">


              <h3 className="blog-card-one__title blog-details__title" style={{ color: "orange" }} >Healthcare</h3>
              <div className="blog-details__content">
                <p>Digital transformation means that healthcare organizations are now moving to the Cloud and adopting new technologies, such as connected medical devices,
                  and paving the way to precision medicine.</p>
                <p>Patient data is a lucrative target for hackers, and with the rise of electronic medical records (EMRs) it has become highly accessible.
                  Healthcare institutions need a resilient cybersecurity solution that takes the burden of protection off their staff, who need to concentrate on treating patients.</p>
                <br />
                <h4 style={{ color: "orange" }}>How can Right Time Limited Help?</h4>
                <p>
                  Our healthcare cybersecurity consultants will assist with the strategy of healthcare providers covering the areas are-

                  <ul>
                    <li>Medical Device Penetration Testing</li>
                    <li>Network Segmentation for Medical Equipment</li>
                    <li>Device Inventory and Risk Analysis</li>
                    <li>Continuous Vulnerability Detection and Response</li>
                    <li>Continuous Incident Monitoring and Response</li>
                    <li>Medical Device Risk Assessments</li>
                    <li>Vendor Risk Management and Reviews</li>
                    <li>Data Inventory</li>
                  </ul>

                  <h5 style={{ color: "orange" }}>Some of the areas where we assist health insurance providers</h5>

                  <ul>
                    <li>Data Inventory and Risk Analysis</li>
                    <li>HIPAA Penetration Testing Services</li>
                    <li>Vendor Risk Management and Reviews</li>
                    <li>HITRUST Certification</li>
                    <li>Continuous Incident Monitoring and Response</li>

                  </ul>
                </p>
              </div>
              <br/>
             <a href = "contact-us">       <Button style={{backgroundColor:"#fd7e14"}} >Contact Us</Button></a>




            </div>
            
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body  className="bg-dark rounded-3">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className ="text-light">Name</Form.Label>
              <Form.Control
                type="input"
                placeholder="name"
                autoFocus
              />
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
              />
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="input"
                placeholder="subject"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Details</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Form>
          <Modal.Footer className = "bg-dark">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{backgroundColor:"#fd7e14"}} onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
          
        </Modal.Body>
       
      </Modal><br /><br /><br />


    </div>
  )
}

export default HealthCare
